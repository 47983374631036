import { createApp } from "vue";
import App from "./App.vue";
import Antd from "ant-design-vue";
import router from "./router";
import store from "./store";
import "ant-design-vue/dist/antd.css";
import { DownOutlined } from "@ant-design/icons-vue";
import "dayjs/locale/zh-cn";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
  duration: 1000,
  easing: "ease-in-out-back",
});

import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

import "./icons";
import SvgIcon from "@/components/SvgIcon/index";

//引入全局样式
import "./assets/css/common.css";

//富文本
import Tinymce from "@/components/tinymce/Tinymce.vue";

//引入
import "./permission.js"

// 代码高亮
import "highlight.js/styles/atom-one-dark.css";
import "highlight.js/lib/common";
import hljsVuePlugin from "@highlightjs/vue-plugin";

const app = createApp(App);

//过滤器
app.config.globalProperties.$filters = {
  dateFormat: (arr, val) => {
    let name = "";
    arr.forEach((element) => {
      if (element.id === val) {
        name = element.name;
        return;
      }
    });
    return name;
  },
  toWhereFormat: (data) => {
    const toWhere = typeof data === "number" ? "Q" + data : data;
    return toWhere;
  },
  filterateTags: (data) => {
    if (data) {
      data = data.replace(/<[^>]+>/g, "");
      data = data.replace(/&nbsp;/gi, "");
    }
    return data;
  },
  filterTimer: (data) => {
    if (data < 10) {
      data = "00:0" + data;
    } else if (10 <= data && data < 60) {
      data = "00:" + data;
    } else if (data == 60) {
      data = "01:00";
    }
    return data;
  },
  isJUmp: (typeId) => {
    let isJumpa = false;
    switch (typeId) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 10:
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
      case 16:
      case 17:
      case 18:
      case 20:
        isJumpa = true;
        break;
    }
    return isJumpa;
  },
  isOptions:(typeId)=>{
    let isJumpa = false;
    switch (typeId) {
      case 1:
      case 2:
      case 4:
      case 6:
      case 7:
      case 8:
      case 9:
      case 11:
      case 13:
      case 14:
      case 16:
      case 17:
      case 18:
      case 20:
      case 23:
        isJumpa = true;
        break;
    }
    return isJumpa;
  },
  isReference: (typeId, data) => {
    let reference = false;
    switch (typeId) {
      case 1:
      case 2:
      case 4:
      case 6:
      case 7:
      case 8:
      case 9:
      case 11:
      case 13:
      case 14:
      case 16:
      case 17:
      case 18:
        reference = true;
        break;
    }

    if (data) {
      switch (data.typeId) {
        case 6:
          if (typeId !== 6) {
            reference = false;
          }
          break;
        case 18:
          if (typeId !== 18 && typeId !== 6) {
            reference = false;
          }
          break;
      }
      if (typeId === 9) {
        reference = false;
      }
    }
    return reference;
  },
};

app
  .component("svg-icon", SvgIcon)
  .component("down-outlined", DownOutlined)
  .component("Tinymce", Tinymce)
  .use(hljsVuePlugin)
  .use(Antd)
  .use(Vue3ColorPicker)
  .use(store)
  .use(router)
  .mount("#app");
