import { createStore } from "vuex";
export default createStore({
  state: {
    projectId: null, //问卷id
    groupList: [], //分组数据
    projectState: {}, //问卷状态保存
    questionTiTle: null, //问卷标题
    spin: false, //加载中
    imageShow: false, //我的图片显示
    draggable: true, //是否拖拽，input光标选中会与拖拽行为发生冲突，需要在input光标得到焦点时将其赋值为false
    previewMode: 1, // 预览模式
    search: null, // 设计首页搜索，跳转到对应题型
    outline: null, //点击大纲条目，跳转对应位置
    jumpLogicShow: false, //跳转逻辑组件显示与隐藏
    editJumpcIndex: null, //编辑时需要传递的索引
    editJumpLogicShow: false, //编辑跳转逻辑组件显示与隐藏
    showLogicShow: false, //显示逻辑组件显示与隐藏
    editShowLogicShow: false, // 编辑显示逻辑
    referenceShow: false, //选项引用显示与隐藏
    editReferenceShow: false, //编辑选项引用显示与隐藏
    optionShow: false, //选项逻辑
    editOptionLogicShow: false, // 编辑选项逻辑
  },
  getters: {},
  mutations: {
    //问卷id
    changeProjectId(state, value) {
      state.projectId = value;
    },
    //分组数据
    changeGroupList(state, value) {
      state.groupList = value;
    },
    //问卷状态保存
    changeProjectState(state, value) {
      state.projectState = value;
      localStorage.setItem("projectState", JSON.stringify(state.projectState));
    },
    //问卷标题
    changeQuestionTiTle(state, value) {
      state.questionTiTle = value;
    },
    //加载中
    changeSpin(state, value) {
      state.spin = value;
    },
    //图片资源
    changeImageShow(state, value) {
      state.imageShow = value;
    },
    //是否拖拽
    changeDraggable(state, value) {
      state.draggable = value;
    },
    //预览模式
    changePreviewMode(state, value) {
      state.previewMode = value;
    },
    // 设计首页搜索，跳转到对应题型
    changeSearch(state, value) {
      state.search = value;
    },
    //点击大纲条目，跳转对应位置
    changeOutline(state, value) {
      state.outline = value;
    },
    //跳转逻辑组件显示与隐藏
    changeJumpLogic(state, value) {
      state.jumpLogicShow = value;
    },
    //编辑时需要传递的索引
    changeEditJumpcIndex(state, value) {
      state.editJumpcIndex = value;
    },
    //编辑跳转逻辑组件显示与隐藏
    changeEditJumpLogicShow(state, value) {
      state.editJumpLogicShow = value;
    },
    //显示逻辑组件显示与隐藏
    changeShowLogic(state, value) {
      state.showLogicShow = value;
    },
    //编辑显示逻辑
    changeEditShowLogicShow(state, value) {
      state.editShowLogicShow = value;
    },
    //选项引用显示与隐藏
    changeReferenceShow(state, value) {
      state.referenceShow = value;
    },
    //编辑选项引用显示与隐藏
    changeEditReferenceShow(state, value) {
      state.editReferenceShow = value;
    },
    //选项逻辑
    changeOptionShow(state, value) {
      state.optionShow = value;
    },
    //编辑选项逻辑
    changeEditOptionShow(state, value) {
      state.editOptionLogicShow = value;
    },
  },
  actions: {},
  modules: {},
});
