<template>
  <div class="header-main">
    <div class="header">
      <div class="header-left">
        <img
          alt="logo"
          @click="router.push('/')"
          src="@/assets/images/logo.png"
        />
        <span class="title"
          >{{ $filters.filterateTags(store.state.questionTiTle) }}
        </span>
        <span class="release" v-if="store.state.projectState.state === 1"
          >未发布</span
        >
        <span
          class="release"
          v-if="
            store.state.projectState.state === 2 &&
            !store.state.projectState.changed
          "
          >已发布</span
        >
        <span
          class="release"
          v-if="
            store.state.projectState.state === 2 &&
            store.state.projectState.changed
          "
          >已修改,未发布</span
        >
        <span class="release" v-if="store.state.projectState.state === 3"
          >已关闭</span
        >
        <img
          class="save-status"
          v-if="!store.state.spin"
          alt="图片"
          src="@/assets/images/check.png"
        />
        <a-spin class="save-status" v-else />
        <a-tooltip
          overlay-class-name="tooltipColor"
          :title="`最近保存于${store.state.projectState.updateTime}`"
          color="#fff"
          placement="right"
        >
          <span class="save">{{
            store.state.spin ? "自动保存中..." : "已保存"
          }}</span>
        </a-tooltip>
      </div>
      <div class="header-right">
        <img
          class="eyes"
          @click="skip"
          alt="图片"
          src="@/assets/images/eyes.png"
        />
        <span @click="skip">预览</span>
        <button
          class="ant-btn"
          @click="jump(`/subject/release?id=${store.state.projectId}`)"
        >
          <div>发布</div>
        </button>
        <img
          class="question-mark"
          alt="图片"
          src="@/assets/images/question-mark.png"
        />

        <a-dropdown>
          <img class="mine" alt="图片" src="@/assets/images/mine.png" />
          <template #overlay>
            <a-menu>
              <a-menu-item key="1">
                <div class="dropdown-name">用户昵称</div>
              </a-menu-item>
              <a-menu-item
                v-for="item in state.menuList"
                :key="item.name"
                @click="clickMenu(item)"
              >
                <div class="dropdown-box">
                  <img :src="item.url" alt="图片" />
                  <span>{{ item.name }}</span>
                </div>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>
    <div class="navigation">
      <div class="navigation-left" @click="jump(`/category/myQuestionnaire`)">
        <img class="go-back" alt="logo" src="@/assets/images/go-back.png" />
        <span>返回编辑</span>
      </div>
      <div class="navigation-content">
        <span
          :class="index === state.contentIndex ? 'select' : ''"
          v-for="(item, index) in state.contentList"
          :key="index"
          @click="changeIndex(index)"
          >{{ item }}</span
        >
        <div :class="['select-line', `active${state.contentIndex}`]"></div>
      </div>
      <div class="navigation-right">
        <a-select
          v-model:value="state.search"
          show-search
          @change="changeSearch"
          placeholder="请输入搜索内容"
          style="width: 200px"
        >
          <a-select-option
            v-for="item in options"
            :value="'Q' + item.codeNum + item.name"
            :key="item.codeNum"
            >Q{{ item.codeNum }}.{{
              $filters.filterateTags(item.name)
            }}</a-select-option
          >
        </a-select>
      </div>
    </div>
  </div>
  <router-view />
</template>
<script setup >
import { reactive, defineEmits, watch, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const state = reactive({
  contentList: ["设计", "主题", "设置", "发布", "报表"],
  contentIndex: 0,
  search: null,
  id: null,
  menuList: [
    {
      name: "个人中心",
      url: require("@/assets/images/category/personal-center.png"),
    },
    {
      name: "我的钱包",
      url: require("@/assets/images/category/my-wallet.png"),
    },
    {
      name: "模板收藏",
      url: require("@/assets/images/category/template-collection.png"),
    },
    {
      name: "数据下载",
      url: require("@/assets/images/category/data-download.png"),
    },
    {
      name: "我的消息",
      url: require("@/assets/images/category/my-message.png"),
    },
    {
      name: "退出登录",
      url: require("@/assets/images/category/log-out.png"),
    },
  ],
});
const router = useRouter();
const store = useStore();

watch(
  () => router.currentRoute.value,
  (newValue) => {
    switch (newValue.path) {
      case "/subject/design":
        state.contentIndex = 0;
        break;
      case "/subject/theme":
        state.contentIndex = 1;
        break;
      case "/subject/setup":
      case "/subject/setupQuota":
        state.contentIndex = 2;
        break;
      case "/subject/release":
        state.contentIndex = 3;
        break;
      case "/subject/journaling":
        state.contentIndex = 4;
        break;
    }
  },
  { immediate: true }
);

onMounted(() => {
  if (!store.state.projectId) {
    if (localStorage.getItem("projectId")) {
      store.commit("changeProjectId", localStorage.getItem("projectId"));
    }

    if (localStorage.getItem("projectState")) {
      state.projectState = JSON.parse(localStorage.getItem("projectState"));
      store.commit("changeProjectState", state.projectState);
    }
    if (localStorage.getItem("questionTiTle")) {
      const questionTiTle = localStorage.getItem("questionTiTle");
      store.commit("changeQuestionTiTle", questionTiTle);
    }
  }
});
const changeIndex = (index) => {
  if (state.contentIndex !== index) {
    state.contentIndex = index;
    switch (index) {
      case 0:
        router.push(`/subject/design?id=${store.state.projectId}`);
        break;
      case 1:
        router.push(`theme?id=${store.state.projectId}`);
        break;
      case 2:
        router.push(`setup?id=${store.state.projectId}`);
        break;
      case 3:
        router.push(`release?id=${store.state.projectId}`);
        break;
      case 4:
        router.push(`journaling?id=${store.state.projectId}`);
        break;
    }
  }
};
const skip = () => {
  const index = location.href.indexOf("#");
  window.open(location.href.substring(0, index) + "#/preview");
  //预览的时候缓存
  localStorage.setItem("groupList", JSON.stringify(store.state.groupList));
};

//计算属性
const options = computed(() => {
  let option = [];
  if (store.state.groupList && store.state.groupList.length !== 0) {
    const groupList = JSON.parse(JSON.stringify(store.state.groupList));
    for (let i = 0; i < groupList.length; i++) {
      if (groupList[i].fromlist) {
        option = option.concat(groupList[i].fromlist);
      }
    }
  }
  return option;
});
const changeSearch = () => {
  const fromlistItem = options.value.find((item) => {
    return "Q" + item.codeNum + item.name === state.search;
  });
  store.commit("changeSearch", fromlistItem.codeNum);
};
const jump = (path) => {
  router.push(path);
};

//点击用户中心
const clickMenu = (data) => {
  switch (data.name) {
    case "个人中心":
      localStorage.setItem("lastPath", router.currentRoute.value.fullPath);
      router.push("/personalCenter/basicInformation?userType=2");
      break;
    case "我的钱包":
      localStorage.setItem("lastPath", router.currentRoute.value.fullPath);
      router.push("/personalCenter/financeManage?type=1&userType=2");
      break;
    case "退出登录":
      localStorage.setItem("token", "");
      router.push("/login");
      break;
  }
};
</script>
 
<style lang="scss" scoped>
.header-main {
  min-width: 1366px;
  background-color: #f3f4f7;
  font-family: Source Han Sans CN-Regular;
  font-size: 14px;
  padding-bottom: 16px;
  color: #333333;
}
.header {
  background-color: #fff;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header-left {
    display: flex;
    align-items: center;
    img {
      position: relative;
      bottom: 1px;
      width: 86px;
      height: 32px;
      margin-left: 11px;
    }
    .title {
      font-size: 16px;
      font-family: Source Han Sans CN-Bold;
      margin-left: 42px;
    }
    .release {
      color: #f6232d;
      margin-left: 34px;
    }
    .save-status {
      position: relative;
      bottom: 1px;
      width: 16px;
      height: 16px;
      margin-left: 40px;
    }
    .save {
      color: #666666;
      margin-left: 10px;
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    img {
      width: 18px;
      height: 18px;
    }
    .mine {
      margin-right: 38px;
      width: 18px;
      height: 18px;
    }
    .question-mark {
      margin-right: 35px;
      margin-left: 41px;
      width: 20px;
      height: 20px;
    }
    .ant-btn {
      width: 74px;
      height: 32px;
      background: #0076ff;
      opacity: 1;
      margin-left: 35px;
      color: #fff;
      div {
        letter-spacing: 0;
      }
    }
    span {
      cursor: pointer;
    }
    .eyes {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      cursor: pointer;
    }
  }
}
.navigation {
  background-color: #fff;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1px;
  .navigation-left {
    width: 320px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .go-back {
      width: 16px;
      height: 16px;
      margin-left: 20px;
    }
    span {
      font-size: 14px;
      margin-left: 8px;
    }
  }
  .navigation-content {
    span {
      display: inline-block;
      margin: 0 30px;
      font-size: 14px;
      cursor: pointer;
      line-height: 50px;
    }
    .select {
      color: #0076ff;
    }
    .select-line {
      width: 25px;
      height: 2px;
      background-color: #0076ff;
      transition: transform 0.3s;
      transform: translateX(0);
    }
    .active0 {
      transform: translateX(30px);
    }
    .active1 {
      transform: translateX(119px);
    }
    .active2 {
      transform: translateX(208px);
    }
    .active3 {
      transform: translateX(295px);
    }
    .active4 {
      transform: translateX(384px);
    }
  }
  .navigation-right {
    display: flex;
    align-items: center;
    width: 316px;
    span {
      font-size: 16px;
      font-family: Microsoft YaHei;
      color: #333333;
      margin-left: 10px;
    }
    .search {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
<style scoped>
.ant-select {
  width: 180px !important;
}
.navigation /deep/ .ant-select-selector {
  background-color: #f5f6fa !important;
  box-shadow: none !important;
  border: none !important;
}
.dropdown-name {
  min-width: 100px;
}
.dropdown-box {
  min-width: 100px;
  display: flex;
  align-items: center;
}
.dropdown-box > img {
  width: 16px;
  height: 16px;
  margin-right: 15px;
}
.dropdown-box > span {
  font-size: 14px;
  margin-right: 10px;
}
.ant-spin >>> .ant-spin-dot {
  font-size: 17px;
}
</style>>
