import { createRouter, createWebHashHistory } from "vue-router";
import Layout from "@/components/common/header.vue";
import Category from "@/components/category/header";
import PersonalCenter from "@/components/PersonalCenter/herder.vue";
import home from "@/views/home.vue";
const routes = [
  {
    path: "/preview",
    name: "preview",
    component: () => import("@/views/Preview.vue"),
  },
  {
    path: "/reply",
    name: "reply",
    component: () => import("@/views/Reply.vue"),
  },
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login.vue"),
  },
  {
    path: "/home",
    component: home,
    redirect: "/home/product",
    name: "首页",
    children: [
      {
        path: "product",
        component: () => import("@/views/homePage/product.vue"),
        meta: { title: "产品介绍" },
      },
      {
        path: "sample",
        component: () => import("@/views/homePage/sample.vue"),
        meta: { title: "样本服务" },
      },
      {
        path: "template",
        component: () => import("@/views/homePage/template.vue"),
        meta: { title: "免费模版" },
      },
      {
        path: "version",
        component: () => import("@/views/homePage/version.vue"),
        meta: { title: "版本服务" },
      },
      {
        path: "resources",
        component: () => import("@/views/homePage/resources.vue"),
        meta: { title: "资源中心" },
      },
    ],
  },
  {
    path: "/subject",
    component: Layout,
    redirect: "/subject/design",
    name: "问卷设计",
    children: [
      {
        path: "design",
        component: () => import("@/views/subject/design"),
        meta: { title: "设计" },
      },
      {
        path: "theme",
        component: () => import("@/views/subject/theme"),
        meta: { title: "主题" },
      },
      {
        path: "setup",
        component: () => import("@/views/subject/setup"),
        meta: { title: "设置" },
      },
      {
        path: "setupQuota",
        component: () => import("@/views/subject/setupQuota"),
        meta: { title: "配额设置" },
      },
      {
        path: "release",
        component: () => import("@/views/subject/release"),
        meta: { title: "发布" },
      },
      {
        path: "journaling",
        component: () => import("@/views/subject/journaling"),
        meta: { title: "报表" },
      },
    ],
  },
  {
    path: "/category",
    component: Category,
    redirect: "/category/myQuestionnaire",
    name: "我的问卷",
    children: [
      {
        path: "myQuestionnaire",
        component: () => import("@/views/category/MyQuestionnaire"),
        meta: { title: "我的问卷" },
      },
      {
        path: "createQuestionnaire",
        component: () => import("@/views/category/CreateQuestionnaire"),
        meta: { title: "创建问卷" },
      },
      {
        path: "folderQuestionnaire",
        component: () => import("@/views/category/FolderQuestionnaire"),
        meta: { title: "我的问卷" },
      },
      {
        path: "commonTemplates",
        component: () => import("@/views/category/CommonTemplates"),
        meta: { title: "常用模板" },
      },
      {
        path: "recycleBin",
        component: () => import("@/views/category/RecycleBin"),
        meta: { title: "设置" },
      },
    ],
  },
  {
    path: "/personalCenter",
    component: PersonalCenter,
    redirect: "/personalCenter/basicInformation",
    name: "个人中心",
    children: [
      {
        path: "basicInformation",
        component: () => import("@/views/personalCenter/basicInformation"),
        meta: { title: "个人中心" },
      },
      {
        path: "financeManage",
        component: () => import("@/views/personalCenter/financeManage"),
        meta: { title: "财务管理" },
      },
      {
        path: "withdrawalApply",
        component: () => import("@/views/personalCenter/withdrawalApply"),
        meta: { title: "提现申请" },
      },
      {
        path: "withdrawalHistory",
        component: () => import("@/views/personalCenter/withdrawalHistory"),
        meta: { title: "历史记录" },
      },
      {
        path: "credentials",
        component: () => import("@/views/personalCenter/credentials"),
        meta: { title: "认证信息" },
      },
      {
        path: "accountUpgrade",
        component: () => import("@/views/personalCenter/accountUpgrade"),
        meta: { title: "账号升级" },
      },
      {
        path: "businessManage",
        component: () => import("@/views/personalCenter/businessManage"),
        meta: { title: "企业管理" },
      },
      {
        path: "businessHistoty",
        component: () => import("@/views/personalCenter/businessHistoty"),
        meta: { title: "企业管理" },
      },
      {
        path: "departmentManage",
        component: () => import("@/views/personalCenter/departmentManage"),
        meta: { title: "历史订单" },
      },
      {
        path: "userManage",
        component: () => import("@/views/personalCenter/userManage"),
        meta: { title: "用户管理" },
      },
      {
        path: "userLimits",
        component: () => import("@/views/personalCenter/userLimits"),
        meta: { title: "用户权限" },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
