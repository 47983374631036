
<template>
  <div class="header-main" :style="`background-color:${color}`">
    <div class="header">
      <div class="header-left"  @click="router.push('/')">
        <img alt="logo" src="@/assets/images/logo.png" />
      </div>
      <div class="header-content">
        <span
          :class="index === state.contentIndex ? 'select' : ''"
          v-for="(item, index) in state.contentList"
          :key="index"
          @click="changeIndex(index)"
          >{{ item }}</span
        >
        <div :class="['select-line', `active${state.contentIndex}`]"></div>
      </div>
      <div class="header-right">
        <img
          class="question-mark"
          alt="图片"
          src="@/assets/images/question-mark.png"
        />

        <a-dropdown>
          <img class="mine" alt="图片" src="@/assets/images/mine.png" />
          <template #overlay>
            <a-menu>
              <a-menu-item key="1">
                <div class="dropdown-name">用户昵称</div>
              </a-menu-item>
              <a-menu-item
                v-for="item in state.menuList"
                :key="item.name"
                @click="clickMenu(item)"
              >
                <div class="dropdown-box">
                  <img :src="item.url" alt="图片" />
                  <span>{{ item.name }}</span>
                </div>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>
    <div class="notice" v-if="state.notice">
      <div class="left">
        <div class="title"><span class="bold">公告</span>(1/2)</div>
        <div class="substance">企业完成认证，可以解锁更多强大功能。</div>
      </div>
      <div class="right">
        <span>前往认证</span>
        <img
          class="close"
          @click="state.notice = false"
          src="@/assets/images/design/close.png"
          alt="图片"
        />
      </div>
    </div>
    <router-view />
  </div>
</template>
<script setup >
import { reactive, defineEmits, watch, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const state = reactive({
  contentList: ["我的问卷", "常用模板", "回收站"],
  contentIndex: 0,
  notice: true,
  menuList: [
    {
      name: "个人中心",
      url: require("@/assets/images/category/personal-center.png"),
    },
    {
      name: "我的钱包",
      url: require("@/assets/images/category/my-wallet.png"),
    },
    {
      name: "模板收藏",
      url: require("@/assets/images/category/template-collection.png"),
    },
    {
      name: "数据下载",
      url: require("@/assets/images/category/data-download.png"),
    },
    {
      name: "我的消息",
      url: require("@/assets/images/category/my-message.png"),
    },
    {
      name: "退出登录",
      url: require("@/assets/images/category/log-out.png"),
    },
  ],
});
const router = useRouter();
const store = useStore();

watch(
  () => router.currentRoute.value.fullPath,
  () => {
    assignmentIndex();
  }
);
onMounted(() => {
  assignmentIndex();
});

const assignmentIndex = () => {
  switch (router.currentRoute.value.fullPath) {
    case "/category/myQuestionnaire":
    case "/category/createQuestionnaire":
      state.contentIndex = 0;
      break;
    case "/category/commonTemplates":
      state.contentIndex = 1;
      break;
    case "/category/recycleBin":
      state.contentIndex = 2;
      break;
  }
};

const changeIndex = (index) => {
  if (state.contentIndex !== index) {
    state.contentIndex = index;
    switch (index) {
      case 0:
        router.push("myQuestionnaire");
        break;
      case 1:
        router.push("commonTemplates");
        break;
      case 2:
        router.push("recycleBin");
        break;
    }
  }
};
const skip = () => {
  const index = location.href.indexOf("#");
  window.open(location.href.substring(0, index) + "#/preview");
  //预览的时候缓存
  localStorage.setItem("groupList", JSON.stringify(store.state.groupList));
};

//计算属性
const color = computed(() => {
  let color = "#f5f6fa";
  if (router.currentRoute.value.fullPath === "/category/createQuestionnaire") {
    color = "#fff";
  }
  return color;
});

//点击用户中心
const clickMenu = (data) => {
  console.log("data", data);
  switch (data.name) {
    case "个人中心":
      localStorage.setItem("lastPath", router.currentRoute.value.fullPath);
      router.push("/personalCenter/basicInformation?userType=2");
      break;
    case "我的钱包":
      localStorage.setItem("lastPath", router.currentRoute.value.fullPath);
      router.push("/personalCenter/financeManage?type=1&userType=2");
      break;
    case "退出登录":
      localStorage.setItem("token", "");
      router.push('/login');
      break;
  }
};
</script>
 
<style lang="scss" scoped>
.header-main {
  min-width: 1366px;
  font-family: Source Han Sans CN-Regular;
  font-size: 14px;
  color: #333333;
  min-height: 100vh;
}
.header {
  background-color: #fff;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header-left {
    display: flex;
    align-items: center;
    width: 152px;
    img {
      position: relative;
      bottom: 1px;
      width: 86px;
      height: 32px;
      margin-left: 11px;
    }
  }
  .header-content {
    span {
      display: inline-block;
      margin: 0 60px;
      font-size: 14px;
      cursor: pointer;
      line-height: 50px;
    }
    .select {
      color: #0076ff;
    }
    .select-line {
      width: 55px;
      height: 2px;
      background-color: #0076ff;
      transition: transform 0.3s;
      transform: translateX(0);
    }
    .active0 {
      transform: translateX(60px);
    }
    .active1 {
      transform: translateX(235px);
    }
    .active2 {
      width: 40px;
      transform: translateX(412px);
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    .mine {
      margin-right: 38px;
      width: 18px;
      height: 18px;
    }
    .question-mark {
      margin-right: 35px;
      margin-left: 41px;
      width: 20px;
      height: 20px;
    }
  }
}
.notice {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 25px;
  width: 1300px;
  height: 36px;
  background: #dce9fb;
  line-height: 36px;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  justify-content: space-between;
  .left {
    display: flex;
    width: calc(100% - 200px);
    .title {
      margin: 0 33px 0 14px;
      flex-shrink: 0;
      width: 80px;
      .bold {
        font-weight: bold;
        margin-right: 4px;
      }
    }
    .substance {
      width: calc(100% - 200px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
  }
  .right {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 200px;
    justify-content: flex-end;
    span {
      font-size: 14px;
      cursor: pointer;
      color: #0076ff;
      font-weight: 500;
    }
    img {
      width: 16px;
      height: 16px;
      margin: 0 20px 0 80px;
      cursor: pointer;
    }
  }
}
</style>
<style scoped>
.dropdown-name {
  min-width: 100px;
}
.dropdown-box {
  min-width: 100px;
  display: flex;
  align-items: center;
}
.dropdown-box > img {
  width: 16px;
  height: 16px;
  margin-right: 15px;
}
.dropdown-box > span {
  font-size: 14px;
  margin-right: 10px;
}
</style>
