<template>
  <div class="personal-main">
    <div class="personal-herder">
      <img @click="goback" src="@/assets/images/personal/reversal.png" />
      <div>个人中心</div>
    </div>
    <div class="personal-content">
      <div class="personal-left">
        <div
          :class="[
            'navigation',
            { 'navigation-active': state.active === index },
          ]"
          v-for="(item, index) in state.navigation"
          :key="item.name"
          @click="clickNavigation(item, index)"
        >
          <svg-icon :icon-class="item.icon" class="svg" />
          <div class="navigation-name">{{ item.name }}</div>
        </div>
      </div>
      <div class="personal-right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script  setup>
import Icon from "@ant-design/icons-vue/lib/components/Icon";
import { reactive, ref, onMounted } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
//data
const state = reactive({
  navigation: [
    {
      name: "基本信息",
      icon: "basic-information",
      path: "/personalCenter/basicInformation",
    },
    {
      name: "财务管理",
      icon: "finance-manage",
      path: "/personalCenter/financeManage",
    },
    {
      name: "认证信息",
      icon: "credentials",
      path: "/personalCenter/credentials",
    },
    {
      name: "账号升级",
      icon: "account-upgrade",
      path: "/personalCenter/accountUpgrade",
    },
  ],
  active: 0,
});

//刷新
onMounted(() => {
  if (router.currentRoute.value.query.type) {
    state.active = parseInt(router.currentRoute.value.query.type);
  }

  if (router.currentRoute.value.query.userType == 2) {
    state.navigation = [
      {
        name: "基本信息",
        icon: "basic-information",
        path: "/personalCenter/basicInformation?userType=2",
      },
      {
        name: "企业管理",
        icon: "business-manage",
        path: "/personalCenter/businessManage?userType=2",
      },
      {
        name: "部门管理",
        icon: "department-manage",
        path: "/personalCenter/departmentManage?userType=2",
      },
      {
        name: "用户管理",
        icon: "user-manage",
        path: "/personalCenter/userManage?userType=2",
      },
      {
        name: "财务管理",
        icon: "finance-manage",
        path: "/personalCenter/financeManage?userType=2",
      },
    ];
    if (router.currentRoute.value.query.type) {
      state.active = 4;
    }
  }
  switch (router.currentRoute.value.fullPath) {
    case "/personalCenter/basicInformation":
      state.active = 0;
      break;
    case "/personalCenter/financeManage":
    case "/personalCenter/withdrawalApply":
    case "/personalCenter/withdrawalHistory":
      state.active = 1;
      break;
    case "/personalCenter/credentials":
      state.active = 2;
      break;
    case "/personalCenter/accountUpgrade":
      state.active = 3;
      break;
  }

  switch (router.currentRoute.value.fullPath) {
    case "/personalCenter/basicInformation?userType=2":
      state.active = 0;
      break;
    case "/personalCenter/businessManage?userType=2":
    case "/personalCenter/businessHistoty?userType=2":
      state.active = 1;
      break;
    case "/personalCenter/departmentManage?userType=2":
      state.active = 2;
      break;
    case "/personalCenter/userManage?userType=2":
    case "/personalCenter/userLimits?userType=2":
      state.active = 3;
      break;
    case "/personalCenter/financeManage?userType=2":
    case "/personalCenter/withdrawalApply?userType=2":
    case "/personalCenter/withdrawalHistory?userType=2":
      state.active = 4;
      break;
  }
});
//回退
const goback = () => {
  router.push(localStorage.getItem("lastPath"));
};
//点击导航
const clickNavigation = (data, index) => {
  state.active = index;
  router.push(data.path);
};
</script>

<style lang="scss" scoped>
.personal-main {
  width: 100%;
  height: 100vh;
  min-width: 1000px;
}
.personal-herder {
  display: flex;
  justify-content: center;
  height: 52px;
  align-items: center;
  img {
    position: absolute;
    left: 30px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
}
.personal-content {
  width: 100%;
  height: calc(100% - 52px);
  background-color: #f5f6fa;
  display: flex;
  .personal-left {
    height: 100%;
    overflow-y: auto;
    background-color: #fff;
    flex-shrink: 0;
    width: 184px;
    .navigation {
      display: flex;
      width: 151px;
      height: 42px;
      background: #fff;
      border-radius: 4px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 15px;
      align-items: center;
      cursor: pointer;
      .svg {
        font-size: 146x;
        margin: 0 15px 0 14px;
      }
    }
    .navigation-active {
      background-color: #0076ff;
      color: #fff;
    }
  }
  .personal-right {
    width: calc(100% - 16px);
    margin-left: 16px;
    margin-top: 16px;
    height: calc(100% - 16px);
    overflow-y: auto;
    background-color: #fff;
  }
}
</style>