<template>
  <div class="home-mian">
    <div class="navigation">
      <div class="left">
        <img class="logo" src="@/assets/images/logo.png" />
        <div class="navigation-list">
          <span
            :class="[{ active: state.active === item.id }]"
            v-for="item in state.navigation"
            :key="item.id"
            @click="Jump(item.path)"
            >{{ item.name }}</span
          >
        </div>
      </div>
      <div class="right">
        <div class="button" @click="Jump('/login')">登录</div>
        <span @click="Jump('/login?type=2')">注册</span>
      </div>
      <a-dropdown :trigger="['click']">
        <img
          class="storage"
          src="@/assets/images/home/storage.png"
          @click.prevent
        />
        <template #overlay>
          <a-menu>
            <a-menu-item
              v-for="item in state.navigation"
              @click="Jump(item.path)"
              :key="item.id"
            >
            {{item.name}}
            </a-menu-item>
            <a-menu-item key="6" @click="Jump('/login')"> 登录 </a-menu-item>
            <a-menu-item key="7" @click="Jump('/login?type=2')">
              注册
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
    <router-view />
    <Foot v-if="state.active !== 5"></Foot>
  </div>
</template>
<script setup>
import { reactive, onMounted, onBeforeUnmount, watch } from "vue";
import { useRouter } from "vue-router";
import Foot from "@/views/homePage/foot.vue";
const router = useRouter();

const state = reactive({
  navigation: [
    {
      name: "产品介绍",
      id: 1,
      path: "/home/product",
    },
    {
      name: "样本服务",
      id: 2,
      path: "/home/sample",
    },
    {
      name: "免费模版",
      id: 3,
      path: "/home/template",
    },
    {
      name: "版本服务",
      id: 4,
      path: "/home/version",
    },
    {
      name: "资源中心",
      id: 5,
      path: "/home/resources",
    },
  ],
  active: 1,
});

watch(
  () => router.currentRoute.value,
  (newValue) => {
    switch (newValue.path) {
      case "/home/product":
        state.active = 1;
        break;
      case "/home/sample":
        state.active = 2;
        break;
      case "/home/template":
        state.active = 3;
        break;
      case "/home/version":
        state.active = 4;
        break;
      case "/home/resources":
        state.active = 5;
        break;
    }
  },
  { immediate: true }
);

//跳转
const Jump = (path) => {
  router.push(path);
};
</script>


<style lang="scss" scoped>
.navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
  position: absolute;
  width: 100%;
  .right {
    display: flex;
    font-size: 16px;
    align-items: center;
    color: #0076ff;
    span {
      margin-right: 140px;
      cursor: pointer;
    }
    .button {
      cursor: pointer;
      width: 75px;
      height: 32px;
      line-height: 31px;
      border-radius: 16px;
      padding: 0;
      border: none;
      // background-color: ;
      text-align: center;
      border: 1px solid #0076ff;
      margin-right: 60px;
    }
  }
  .left {
    display: flex;
    align-items: center;
    .logo {
      width: 64px;
      margin-left: 133px;
      margin-right: 76px;
    }
    .navigation-list {
      display: flex;
      align-items: center;
      span {
        margin-right: 67px;
        cursor: pointer;
        font-size: 16px;
      }
      .active {
        color: #0076ff;
      }
    }
  }
  .storage {
    display: none;
    margin-right: 30px;
    cursor: pointer;
    width: 26px;
    height: 26px;
  }
}
.home-mian {
  min-width: 600px;
}
@media only screen and (max-width: 1260px) {
  .navigation {
    .right {
      span {
        margin-right: 70px;
      }
    }
    .left {
      .logo {
        margin-left: 60px;
      }
      .navigation-list {
        span {
          margin-right: 50px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1010px) {
  .navigation {
    .right {
      span {
        margin-right: 70px;
      }
      .button {
        cursor: pointer;
        margin-right: 40px;
      }
    }
    .left {
      .logo {
        margin-right: 50px;
      }
      .navigation-list {
        span {
          margin-right: 30px;
        }
      }
    }
  }
}

@media only screen and (max-width: 862px) {
  .navigation {
    .right {
      span {
        margin-right: 30px;
      }
      .button {
        cursor: pointer;
        margin-right: 20px;
      }
    }
    .left {
      .logo {
        margin-right: 20px;
        margin-left: 20px;
      }
      .navigation-list {
        span {
          margin-right: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 685px) {
  .navigation {
    .right {
      display: none;
    }
    .left {
      .logo {
        margin-right: 20px;
        margin-left: 20px;
      }
      .navigation-list {
        display: none;
      }
    }
    .storage {
      display: block;
    }
  }
}
</style>