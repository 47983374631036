<template>
  <footer>
    <div class="footer-content">
      <div class="glibal">
        <div class="title">产品</div>
        <div class="ietnam">
          <div>问卷工具</div>
          <div>样本服务</div>
          <div>定制研究服务</div>
          <div>定制调研系统</div>
        </div>
      </div>
      <div class="glibal">
        <div class="title">支持</div>
        <div class="ietnam">
          <div>帮助中心</div>
          <div>产品动态</div>
        </div>
      </div>
      <div class="glibal">
        <div class="title">公司</div>
        <div class="ietnam">
          <div>关于我们</div>
          <div>加入我们</div>
        </div>
      </div>
      <div class="glibal">
        <div class="title">友情链接</div>
        <div class="ietnam">
          <div>问卷调查</div>
          <div>问卷调查</div>
          <div>问卷调查</div>
        </div>
      </div>
      <div class="about">
        <div class="number-box">
          <div class="number">000-00000-000</div>
          <div class="title">客服咨询</div>
        </div>
        <div class="number-box">
          <div class="number">000-00000-000</div>
          <div class="title">商务合作</div>
        </div>
        <div class="number-box">
          <div class="number">1234567890@baishulai.com</div>
          <div class="title">销售邮箱</div>
        </div>
      </div>
    </div>

    <div class="about2">
      <div class="number-box">
        <div class="number">000-00000-000</div>
        <div class="title">客服咨询</div>
      </div>
      <div class="number-box">
        <div class="number">000-00000-000</div>
        <div class="title">商务合作</div>
      </div>
      <div class="number-box">
        <div class="number">1234567890@baishulai.com</div>
        <div class="title">销售邮箱</div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="left">
        <div>服务协议</div>
        <div>隐私政策</div>
        <div>免责声明</div>
      </div>
      <div class="right">Copyright © 2023 问卷调查. All Rights Reserved.</div>
    </div>
  </footer>
</template>
<script setup>
</script>
<style lang="scss" scoped>
/* 页脚 */
footer {
  width: 100%;
  background: #f4f5f5;
  padding: 0 140px;
  margin-top: 20px;
  padding-bottom: 21px;
}
.footer-content {
  display: flex;
  justify-content: space-between;
  padding-right: 50px;
  margin-top: 38px;
  padding-top: 66px;
  .title {
    font-size: 16px;
    font-weight: bold;
    color: #323233;
  }
  .about {
    width: 255px;
    .number-box {
      display: flex;
      flex-direction: column;
      margin-bottom: 44px;
      .number {
        font-size: 16px;
        color: #323233;
      }
      .title {
        font-weight: 400;
        color: #666;
        font-size: 14px;
      }
    }
  }
}
.glibal > .ietnam {
  font-size: 14px;
  margin-top: 20px;
}
.glibal > .ietnam > div {
  margin-bottom: 16.25px;
  cursor: pointer;
  font-size: 14px;
  color: #666;
}
.about2 {
  display: none;
  .number-box {
    margin-right: 40px;
    .number {
      font-size: 16px;
      color: #323233;
    }
    .title {
      font-weight: 400;
      color: #666;
      font-size: 14px;
    }
  }
}
.footer-bottom {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  flex-wrap: wrap;
  .left {
    display: flex;
    div {
      margin-right: 40px;
    }
  }
  .right {
    margin-left: 60px;
  }
}

@media only screen and (max-width: 1200px) {
  .footer-content {
    padding-right: 0px;
  }
  footer {
    padding: 0 80px;
  }
}
@media only screen and (max-width: 960px) {
  .footer-content {
    padding-right: 0px;
  }
  footer {
    padding: 0 60px;
  }
}
@media only screen and (max-width: 820px) {
  .footer-content {
    padding-right: 0px;
  }
  footer {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 730px) {
  .about {
    display: none;
  }
  .footer-bottom {
    justify-content: center;
    margin-top: 50px;
    .right {
      margin-left: 0;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 400px;
      text-align: center;
    }
    .left {
      display: flex;
      width: 400px;
      justify-content: center;
      div {
        margin-right: 40px;
      }
      div:nth-child(3) {
        margin-right: 0px;
      }
    }
  }
  .about2 {
    display: flex;
  }
}
</style>
