// ==========tinymce.js==========
/**
 * 1. bullist ：无序列表
 * 2. numlist ：有序列表
 * 3. image ：图片
 * 4. fullscreen ：全屏
 * 5. preview ：预览
 * 6. print ： 打印
 */

export const plugins = ["advlist  autolink autosave code directionality insertdatetime lists  noneditable paste save tabfocus  wordcount"];

export const toolbar = ["fontsizeselect lineheight  bold italic underline strikethrough alignleft aligncenter alignright outdent indent  blockquote undo redo removeformat subscript superscript code bullist numlist forecolor backcolor fullscreen"];
